import React from 'react';
import WhiteSquare from '../assets/images/white-square.png';
import CloseIcon from '../assets/icons/close.svg';
import ArrowUpIcon from '../assets/icons/arrow-up-white.svg';
import DisclaimerInfoIcon from '../assets/icons/disclaimer-info.svg';
import { Button } from './Button';
import { Form } from './Form';
import { CtaProps } from './LegacyCta';
import { TextLink } from './TextLink';
import { PoweredBySniply } from './PoweredBySniply';
import { useIsMobileScreenSize } from '../hooks/use-is-mobile-screen-size';

export const LegacyCtaCandy: React.FC<CtaProps> = ({
    configuration: {
        type,
        headlineText,
        imageUrl,
        urlToBePromoted,
        backgroundColor,
        messageText,
        messageTextColor,
        buttonColor,
        buttonTextColor,
        buttonText,
        formButtonColor,
        formButtonTextColor,
        formPlaceholderText,
        linkText,
        linkTextColor,
        position,
        showSniplyLogo,
        showWhiteSniplyLogo
    },
    disclaimerHref,
    onConvertClick,
    onSubmitFormInputValue,
    isDemoMobileScreen = false
}) => {
    const [hide, setHide] = React.useState(false);

    const isMobileScreen = useIsMobileScreenSize();
    const showMobileView = isDemoMobileScreen || isMobileScreen;

    return hide ? (
        <div
            className={`absolute z-100 bg-white rounded-md ${
                position === 'bottom-left'
                    ? 'bottom-2 left-2'
                    : position === 'bottom-right'
                    ? 'bottom-2 right-2'
                    : position === 'top-left'
                    ? 'top-2 left-2'
                    : 'top-2 right-2'
            } ${
                position === 'bottom-left'
                    ? 'shadow-cta-b-l'
                    : position === 'bottom-right'
                    ? 'shadow-cta-b-r'
                    : position === 'top-left'
                    ? 'shadow-cta-t-l'
                    : 'shadow-cta-t-r'
            }`}
        >
            <div
                style={{ background: backgroundColor }}
                className="relative p-2 w-full h-full rounded-md"
            >
                <img src={imageUrl || WhiteSquare} className="w-20 h-20 object-cover" />
                <button
                    className="p-1 absolute -top-2 -right-2 bg-primary flex items-center justify-center rounded-full"
                    onClick={() => setHide(false)}
                >
                    <img src={ArrowUpIcon} alt="arrow icon" className="h-4 w-4" />
                </button>
            </div>
        </div>
    ) : showMobileView ? (
        <div
            className={`absolute z-100 w-19/20 bg-white rounded-md ${
                position === 'bottom-left'
                    ? 'bottom-2 left-2'
                    : position === 'bottom-right'
                    ? 'bottom-2 right-2'
                    : position === 'top-left'
                    ? 'top-2 left-2'
                    : 'top-2 right-2'
            } ${
                position === 'bottom-left'
                    ? 'shadow-cta-b-l'
                    : position === 'bottom-right'
                    ? 'shadow-cta-b-r'
                    : position === 'top-left'
                    ? 'shadow-cta-t-l'
                    : 'shadow-cta-t-r'
            }`}
        >
            <div
                style={{ background: backgroundColor }}
                className="relative p-2 w-full h-full rounded-md"
            >
                <img src={imageUrl || WhiteSquare} className="w-full h-full object-cover" />
                <div className="absolute p-1 top-3 right-3 flex">
                    <a
                        className="bg-white shrink-0 flex items-center justify-center rounded-full"
                        href={disclaimerHref}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={DisclaimerInfoIcon}
                            alt="dislaimer info icon"
                            className="h-4 w-4"
                        />
                    </a>
                    <button
                        className="ml-2 shrink-0 bg-white flex items-center justify-center rounded-full"
                        onClick={() => setHide(true)}
                    >
                        <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                    </button>
                </div>
                <div className="flex flex-col">
                    <div className="mt-1 p-2">
                        <p className="mt-1 text-grey-light text-sm line-clamp-2">{headlineText}</p>
                        <div className="mt-2">
                            <span
                                className="line-clamp-4 text-sm"
                                style={{ color: messageTextColor }}
                            >
                                {messageText}
                            </span>
                        </div>
                        <div className="mt-2">
                            {type === 'text-link' ? (
                                <TextLink
                                    href={urlToBePromoted}
                                    linkText={linkText}
                                    linkTextColor={linkTextColor}
                                    onConvertClick={onConvertClick}
                                />
                            ) : type === 'form' ? (
                                <Form
                                    buttonColor={formButtonColor}
                                    buttonTextColor={formButtonTextColor}
                                    placeholderText={formPlaceholderText}
                                    onSubmitFormInputValue={onSubmitFormInputValue}
                                    onConvertClick={onConvertClick}
                                    width="full"
                                />
                            ) : (
                                <Button
                                    href={urlToBePromoted}
                                    buttonColor={buttonColor}
                                    buttonText={buttonText}
                                    buttonTextColor={buttonTextColor}
                                    onConvertClick={onConvertClick}
                                />
                            )}
                        </div>
                    </div>
                    {showSniplyLogo && (
                        <div className="h-full mt-2 flex items-end justify-end text-grey-light text-xs">
                            <PoweredBySniply
                                backgroundColor={showWhiteSniplyLogo ? '#2681DB' : backgroundColor}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <div
            className={`absolute z-100 bg-white w-150 h-60 rounded-md ${
                position === 'bottom-left'
                    ? 'bottom-2 left-2'
                    : position === 'bottom-right'
                    ? 'bottom-2 right-2'
                    : position === 'top-left'
                    ? 'top-2 left-2'
                    : 'top-2 right-2'
            } ${
                position === 'bottom-left'
                    ? 'shadow-cta-b-l'
                    : position === 'bottom-right'
                    ? 'shadow-cta-b-r'
                    : position === 'top-left'
                    ? 'shadow-cta-t-l'
                    : 'shadow-cta-t-r'
            }`}
        >
            <div
                style={{ background: backgroundColor }}
                className="p-2 flex w-full h-full rounded-md"
            >
                <img src={imageUrl || WhiteSquare} className="w-56 h-56 object-cover" />
                <div className="flex flex-col w-full h-full">
                    <div className="ml-4">
                        <div className="mt-1 flex items-start justify-between">
                            <p className="text-grey-light text-base line-clamp-2">{headlineText}</p>
                            <div className="flex shrink-0">
                                <a
                                    className="shrink-0"
                                    href={disclaimerHref}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={DisclaimerInfoIcon}
                                        alt="dislaimer info icon"
                                        className="h-4 w-4"
                                    />
                                </a>
                                <button
                                    className="ml-3 shrink-0 flex items-start justify-end"
                                    onClick={() => setHide(true)}
                                >
                                    <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                                </button>
                            </div>
                        </div>
                        <div className="mt-2">
                            <span
                                className="line-clamp-4 text-sm lg:text-base"
                                style={{ color: messageTextColor }}
                            >
                                {messageText}
                            </span>
                        </div>
                        <div className="mt-2">
                            {type === 'text-link' ? (
                                <TextLink
                                    href={urlToBePromoted}
                                    linkText={linkText}
                                    linkTextColor={linkTextColor}
                                    onConvertClick={onConvertClick}
                                />
                            ) : type === 'form' ? (
                                <Form
                                    buttonColor={formButtonColor}
                                    buttonTextColor={formButtonTextColor}
                                    placeholderText={formPlaceholderText}
                                    onSubmitFormInputValue={onSubmitFormInputValue}
                                    onConvertClick={onConvertClick}
                                    width="full"
                                />
                            ) : (
                                <Button
                                    href={urlToBePromoted}
                                    buttonColor={buttonColor}
                                    buttonText={buttonText}
                                    buttonTextColor={buttonTextColor}
                                    onConvertClick={onConvertClick}
                                />
                            )}
                        </div>
                    </div>
                    {showSniplyLogo && (
                        <div className="h-full mt-2 flex items-end justify-end">
                            <PoweredBySniply
                                backgroundColor={showWhiteSniplyLogo ? '#2681DB' : backgroundColor}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
