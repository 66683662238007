import React from 'react';
import WhiteSquare from '../assets/images/white-square.png';
import ArrowUpIcon from '../assets/icons/arrow-up-white.svg';
import CloseIcon from '../assets/icons/close.svg';
import DisclaimerInfoIcon from '../assets/icons/disclaimer-info.svg';
import { useIsMobileScreenSize } from '../hooks/use-is-mobile-screen-size';
import { Button } from './Button';
import { Form } from './Form';
import { CtaProps } from './LegacyCta';
import { PoweredBySniply } from './PoweredBySniply';
import { TextLink } from './TextLink';

export const LegacyCtaFullWidth: React.FC<CtaProps> = ({
    configuration: {
        type,
        headlineText,
        imageUrl,
        urlToBePromoted,
        backgroundColor,
        messageText,
        messageTextColor,
        buttonColor,
        buttonTextColor,
        buttonText,
        formButtonColor,
        formButtonTextColor,
        formPlaceholderText,
        linkText,
        linkTextColor,
        position,
        showSniplyLogo,
        showWhiteSniplyLogo
    },
    disclaimerHref,
    onSubmitFormInputValue,
    onConvertClick,
    isDemoMobileScreen = false
}) => {
    const [hide, setHide] = React.useState(false);

    const isMobileScreen = useIsMobileScreenSize();
    const showMobileView = isDemoMobileScreen || isMobileScreen;

    return hide ? (
        <div
            className={`absolute z-100 bg-white ${
                position === 'bottom-left'
                    ? 'bottom-2 left-2'
                    : position === 'bottom-right'
                    ? 'bottom-2 right-2'
                    : position === 'top-left'
                    ? 'top-2 left-2'
                    : 'top-2 right-2'
            } ${
                position === 'bottom-left'
                    ? 'shadow-cta-b-l'
                    : position === 'bottom-right'
                    ? 'shadow-cta-b-r'
                    : position === 'top-left'
                    ? 'shadow-cta-t-l'
                    : 'shadow-cta-t-r'
            }`}
        >
            <div style={{ background: backgroundColor }} className="relative p-2 w-full h-full">
                <img src={imageUrl || WhiteSquare} className="w-20 h-20 object-cover" />
                <button
                    className="p-1 absolute -top-2 -right-2 bg-primary flex items-center justify-center rounded-full"
                    onClick={() => setHide(false)}
                >
                    <img src={ArrowUpIcon} alt="arrow icon" className="h-4 w-4" />
                </button>
            </div>
        </div>
    ) : showMobileView ? (
        <div
            className={`absolute z-100 w-full h-52 ${
                position === 'bottom-left'
                    ? 'bottom-0 left-0'
                    : position === 'bottom-right'
                    ? 'bottom-0 right-0'
                    : position === 'top-left'
                    ? 'top-10 left-0'
                    : 'top-10 right-0'
            }`}
        >
            <div className="relative">
                <div className="absolute -top-10 left-2 p-2 w-20 h-20 rounded-sm bg-white text-center border border-grey-lighter">
                    <img
                        src={imageUrl || WhiteSquare}
                        className="rounded-sm h-full w-full object-cover"
                    />
                </div>
                <div
                    style={{ background: backgroundColor }}
                    className={`p-3 flex flex-col w-full h-52 ${
                        position === 'bottom-left'
                            ? 'shadow-cta-b-l'
                            : position === 'bottom-right'
                            ? 'shadow-cta-b-r'
                            : position === 'top-left'
                            ? 'shadow-cta-t-l'
                            : 'shadow-cta-t-r'
                    }`}
                >
                    <div className="flex shrink-0 items-start justify-end">
                        {showSniplyLogo && (
                            <PoweredBySniply
                                backgroundColor={showWhiteSniplyLogo ? '#2681DB' : backgroundColor}
                            />
                        )}
                        <a
                            className="ml-4 shrink-0"
                            href={disclaimerHref}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={DisclaimerInfoIcon}
                                alt="dislaimer info icon"
                                className="h-4 w-4"
                            />
                        </a>
                        <button className="ml-2 shrink-0" onClick={() => setHide(true)}>
                            <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                        </button>
                    </div>
                    <div className="mt-6 flex items-start justify-between">
                        <p className="text-grey-light text-sm sm:text-base line-clamp-1">
                            {headlineText}
                        </p>
                    </div>
                    <div>
                        <p
                            className="mt-2 text-sm pr-2 line-clamp-3"
                            style={{ color: messageTextColor }}
                        >
                            {messageText}
                        </p>
                    </div>
                    <div className="mt-3">
                        {type === 'text-link' ? (
                            <div className="h-9">
                                <TextLink
                                    href={urlToBePromoted}
                                    linkText={linkText}
                                    linkTextColor={linkTextColor}
                                    onConvertClick={onConvertClick}
                                />
                            </div>
                        ) : type === 'form' ? (
                            <Form
                                buttonColor={formButtonColor}
                                buttonTextColor={formButtonTextColor}
                                placeholderText={formPlaceholderText}
                                onSubmitFormInputValue={onSubmitFormInputValue}
                                onConvertClick={onConvertClick}
                            />
                        ) : (
                            <Button
                                href={urlToBePromoted}
                                buttonColor={buttonColor}
                                buttonText={buttonText}
                                buttonTextColor={buttonTextColor}
                                onConvertClick={onConvertClick}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div
            className={`absolute z-100 bg-white w-full h-36 lg:h-28 rounded-md ${
                position === 'bottom-left'
                    ? 'bottom-0 left-0'
                    : position === 'bottom-right'
                    ? 'bottom-0 right-0'
                    : position === 'top-left'
                    ? 'top-0 left-0'
                    : 'top-0 right-0'
            } ${
                position === 'bottom-left'
                    ? 'shadow-cta-b-l'
                    : position === 'bottom-right'
                    ? 'shadow-cta-b-r'
                    : position === 'top-left'
                    ? 'shadow-cta-t-l'
                    : 'shadow-cta-t-r'
            }`}
        >
            <div
                style={{ background: backgroundColor }}
                className="p-2 flex justify-between w-full h-full"
            >
                <img
                    src={imageUrl || WhiteSquare}
                    className="h-32 w-32 lg:w-24 lg:h-24 object-cover"
                />
                <div className="pt-2 ml-3 w-full">
                    <div className="flex items-start justify-between">
                        <p className="text-grey-light line-clamp-1">{headlineText}</p>
                        <div className="flex shrink-0">
                            {showSniplyLogo && (
                                <div className="h-full flex items-end justify-end">
                                    <PoweredBySniply
                                        backgroundColor={
                                            showWhiteSniplyLogo ? '#2681DB' : backgroundColor
                                        }
                                    />
                                </div>
                            )}
                            <a
                                className="ml-4 shrink-0"
                                href={disclaimerHref}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={DisclaimerInfoIcon}
                                    alt="dislaimer info icon"
                                    className="h-4 w-4"
                                />
                            </a>
                            <button className="ml-2 shrink-0" onClick={() => setHide(true)}>
                                <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                            </button>
                        </div>
                    </div>
                    <div className={`mt-2 flex flex-col lg:flex-row lg:items-center`}>
                        <span
                            className="text-sm lg:text-base line-clamp-2"
                            style={{ color: messageTextColor }}
                        >
                            {messageText}
                        </span>
                        <div className="mt-2 lg:mt-0 lg:ml-4 lg:mr-1">
                            {type === 'text-link' ? (
                                <div className="h-9 w-56 lg:pt-1">
                                    <TextLink
                                        href={urlToBePromoted}
                                        linkText={linkText}
                                        linkTextColor={linkTextColor}
                                        onConvertClick={onConvertClick}
                                    />
                                </div>
                            ) : type === 'form' ? (
                                <Form
                                    buttonColor={formButtonColor}
                                    buttonTextColor={formButtonTextColor}
                                    placeholderText={formPlaceholderText}
                                    onSubmitFormInputValue={onSubmitFormInputValue}
                                    onConvertClick={onConvertClick}
                                />
                            ) : (
                                <div className="w-56">
                                    <Button
                                        href={urlToBePromoted}
                                        buttonColor={buttonColor}
                                        buttonText={buttonText}
                                        buttonTextColor={buttonTextColor}
                                        onConvertClick={onConvertClick}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
