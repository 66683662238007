import React from 'react';
import ArrowIcon from '../assets/icons/arrow-white.svg';

interface ButtonProps {
    readonly href: string;
    readonly buttonColor: string;
    readonly buttonTextColor: string;
    readonly buttonText: string;
    readonly onConvertClick: () => void;
}

export const Button: React.FC<ButtonProps> = ({
    href,
    buttonColor,
    buttonTextColor,
    buttonText,
    onConvertClick
}) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer"
            style={{ background: buttonColor, color: buttonTextColor }}
            className={`group bg-[${buttonTextColor}] h-9 p-2 rounded-md inline-flex items-center justify-center text-sm`}
            onClick={onConvertClick}
        >
            <span className="line-clamp-1 text-xs translate-x-2 group-hover:translate-x-0 transition-all duration-500">
                {buttonText}
            </span>
            <img
                src={ArrowIcon}
                alt="arrow"
                className="ml-2 h-4 w-4 opacity-0 group-hover:opacity-100 rotate-180 transition-all duration-700"
            />
        </a>
    );
};
