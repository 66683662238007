function getLuminance(hexColor: string): number {
    const hasFullSpec = hexColor.length == 7;
    const match = hexColor.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);

    let r = undefined;
    let g = undefined;
    let b = undefined;

    if (match) {
        r = parseInt(match[0] + (hasFullSpec ? '' : match[0]), 16);
        g = parseInt(match[1] + (hasFullSpec ? '' : match[1]), 16);
        b = parseInt(match[2] + (hasFullSpec ? '' : match[2]), 16);
    }

    if (typeof r != 'undefined') return (r * 299 + g * 587 + b * 114) / 1000;
}

export function getHexColorLightness(hexColor: string): 'lighter' | 'light' | 'medium' | 'dark' {
    const hsp = getLuminance(hexColor);

    if (hsp > 220) {
        return 'lighter';
    }
    if (hsp <= 220 && hsp > 160) {
        return 'light';
    }
    if (hsp <= 160 && hsp > 100) {
        return 'medium';
    } else {
        return 'dark';
    }
}
