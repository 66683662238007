import React from 'react';
import { getHexColorLightness } from '../utils/get-hex-color-lightness';

interface PoweredBySniplyProps {
    readonly backgroundColor: string;
}

export const PoweredBySniply: React.FC<PoweredBySniplyProps> = ({ backgroundColor }) => {
    const backGroundLightness = getHexColorLightness(backgroundColor);

    return (
        <p
            className={`${
                backGroundLightness === 'lighter'
                    ? 'text-grey-light'
                    : backGroundLightness === 'light'
                    ? 'text-grey-lighter'
                    : backGroundLightness === 'medium'
                    ? 'text-white'
                    : 'text-grey-light'
            } text-grey-light text-xs`}
        >
            <span>Powered by </span>
            <a href="https://snip.ly" target="_blank" rel="noreferrer" className="font-semibold">
                Sniply
            </a>
        </p>
    );
};
