import { CtaType } from './create-cta-data';

export function getCtaTypeFromResponse(ctaResult: any): CtaType {
    if (ctaResult.button_action && ctaResult.button_action.is_text === false) {
        return 'button';
    } else if (ctaResult.button_action && ctaResult.button_action.is_text === true) {
        return 'text-link';
    } else if (ctaResult.form_action) {
        return 'form';
    } else if (ctaResult.image_action) {
        return 'image-ad';
    }
}
