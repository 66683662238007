import React from 'react';

interface FormTextInputProps {
    readonly id: string;
    readonly type?: 'text' | 'password';
    readonly labelText?: string;
    readonly explanation?: JSX.Element;
    readonly nextBestActionButton?: JSX.Element;
    readonly value: string;
    readonly placeholder?: string;
    readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readonly autoFocus?: boolean;
    readonly disabled?: boolean;
    readonly bold?: boolean;
    readonly required?: boolean;
    readonly righHandComponent?: JSX.Element;
    readonly disableAutocomplete?: boolean;
}

export const FormTextInput = React.forwardRef<HTMLInputElement, FormTextInputProps>(
    (
        {
            id,
            type = 'text',
            labelText,
            explanation,
            nextBestActionButton,
            value,
            placeholder,
            onChange,
            autoFocus = false,
            disabled = false,
            bold = false,
            required = false,
            righHandComponent,
            disableAutocomplete = false
        }: FormTextInputProps,
        ref
    ) => {
        return (
            <div className="flex flex-col w-full">
                {labelText && (
                    <div className="mb-2 flex items-center justify-between">
                        <div className="grid grid-cols-1">
                            <label htmlFor={id} className="text-sm">
                                {labelText}
                            </label>
                            {explanation && explanation}
                        </div>
                        {nextBestActionButton && nextBestActionButton}
                    </div>
                )}
                <div className="flex items-center justify-between w-full">
                    <input
                        id={id}
                        ref={ref as React.MutableRefObject<HTMLInputElement>}
                        className={`w-full grow border-solid border rounded-l-md px-3 h-9 text-xs text-grey outline-none ${
                            bold ? 'font-bold' : 'font-normal'
                        }`}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        required={required}
                        autoComplete={disableAutocomplete ? 'off' : 'on'}
                    />
                    {righHandComponent && (
                        <span className="ml-2 flex justify-end min-w-max">{righHandComponent}</span>
                    )}
                </div>
            </div>
        );
    }
);

FormTextInput.displayName = 'FormTextInput';
