/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedViewChangeRequest } from '../models/PatchedViewChangeRequest';
import type { View } from '../models/View';
import type { ViewChange } from '../models/ViewChange';
import type { ViewChangeRequest } from '../models/ViewChangeRequest';
import type { ViewRequest } from '../models/ViewRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ViewsService {

    /**
     * @returns View
     * @throws ApiError
     */
    public static viewsCreate({
        requestBody,
    }: {
        requestBody: ViewRequest,
    }): CancelablePromise<View> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/views/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViewChange
     * @throws ApiError
     */
    public static viewsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: ViewChangeRequest,
    }): CancelablePromise<ViewChange> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/views/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViewChange
     * @throws ApiError
     */
    public static viewsPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedViewChangeRequest,
    }): CancelablePromise<ViewChange> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/views/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
