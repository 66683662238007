/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Click } from '../models/Click';
import type { ClickRequest } from '../models/ClickRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClicksService {

    /**
     * @returns Click
     * @throws ApiError
     */
    public static clicksCreate({
        requestBody,
    }: {
        requestBody: ClickRequest,
    }): CancelablePromise<Click> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/clicks/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
