import React from 'react';
import { CtaData } from '../utils/create-cta-data';
import { LegacyCtaSocial } from './LegacyCtaSocial';
import { LegacyCtaFullWidth } from './LegacyCtaFullWidth';
import { LegacyCtaCandy } from './LegacyCtaCandy';
import { LegacyCtaBean } from './LegacyCtaBean';
import { LegacyCtaImage } from './LegacyCtaImage';

export interface CtaProps {
    readonly configuration: CtaData;
    readonly disclaimerHref: string;
    readonly onSubmitFormInputValue: (inputValue: string) => void;
    readonly onConvertClick: () => void;
    readonly isDemoMobileScreen?: boolean;
}

export const LegacyCta: React.FC<CtaProps> = ({
    configuration,
    disclaimerHref,
    onSubmitFormInputValue,
    onConvertClick,
    isDemoMobileScreen
}) => {
    return configuration.type === 'button' ||
        configuration.type === 'text-link' ||
        configuration.type === 'form' ? (
        configuration.theme === 'social' ? (
            <LegacyCtaSocial
                configuration={configuration}
                disclaimerHref={disclaimerHref}
                onSubmitFormInputValue={onSubmitFormInputValue}
                onConvertClick={onConvertClick}
                isDemoMobileScreen={isDemoMobileScreen}
            />
        ) : configuration.theme === 'candy' ? (
            <LegacyCtaCandy
                configuration={configuration}
                disclaimerHref={disclaimerHref}
                onSubmitFormInputValue={onSubmitFormInputValue}
                onConvertClick={onConvertClick}
                isDemoMobileScreen={isDemoMobileScreen}
            />
        ) : configuration.theme === 'bean' ? (
            <LegacyCtaBean
                configuration={configuration}
                disclaimerHref={disclaimerHref}
                onSubmitFormInputValue={onSubmitFormInputValue}
                onConvertClick={onConvertClick}
                isDemoMobileScreen={isDemoMobileScreen}
            />
        ) : configuration.theme === 'fullwidth' ? (
            <LegacyCtaFullWidth
                configuration={configuration}
                disclaimerHref={disclaimerHref}
                onSubmitFormInputValue={onSubmitFormInputValue}
                onConvertClick={onConvertClick}
                isDemoMobileScreen={isDemoMobileScreen}
            />
        ) : null
    ) : configuration.type === 'image-ad' ? (
        <LegacyCtaImage
            configuration={configuration}
            disclaimerHref={disclaimerHref}
            onSubmitFormInputValue={onSubmitFormInputValue}
            onConvertClick={onConvertClick}
            isDemoMobileScreen={isDemoMobileScreen}
        />
    ) : null;
};
