import './public-path';
import styles from './index.css';
import fontStyles from './assets/fonts/fonts.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';

const appContainer = document.getElementById('sniply-cta-root');
appContainer.attachShadow({ mode: 'open' });

const styleTagWithFonts = document.createElement('style');
styleTagWithFonts.innerHTML = fontStyles;

document.head.appendChild(styleTagWithFonts);

// Run the cta app in shadowRoot so styles are encapsulated
// i.e. not leaking from host to app or vice versa.
const root = createRoot(appContainer.shadowRoot);
root.render(
    <div id="sniply-cta-app">
        <style>{styles.toString()}</style>
        <App />
    </div>
);
