import React from 'react';

interface TextLinkProps {
    readonly href: string;
    readonly linkText: string;
    readonly linkTextColor: string;
    readonly onConvertClick: (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => void;
}

export const TextLink: React.FC<TextLinkProps> = ({
    href,
    linkText,
    linkTextColor,
    onConvertClick
}) => {
    return (
        <a
            className="relative group text-sm font-poppins"
            style={{ color: linkTextColor }}
            href={href}
            target="_blank"
            rel="noreferrer"
            onClick={onConvertClick}
        >
            <span className="line-clamp-1">{linkText}</span>
            <span
                style={{ background: linkTextColor }}
                className={`absolute -bottom-1 left-0 w-1/4 h-0.5 transition-all group-hover:w-full duration-500 rounded-md`}
            />
        </a>
    );
};
