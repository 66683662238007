import React from 'react';
import { FormTextInput } from './FormTextInput';
import ArrowWhiteIcon from '../assets/icons/arrow-white.svg';

interface FormProps {
    readonly buttonColor: string;
    readonly buttonTextColor: string;
    readonly placeholderText: string;
    readonly onSubmitFormInputValue: (inputValue: string) => void;
    readonly onConvertClick: (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => void;
    readonly width?: string;
}

export const Form: React.FC<FormProps> = ({
    buttonColor,
    buttonTextColor,
    placeholderText,
    onSubmitFormInputValue,
    onConvertClick
}) => {
    const [inputValue, setInputValue] = React.useState('');
    const [hasFormValueBeenSubmitted, setHasFormValueBeenSubmitted] = React.useState(false);

    return hasFormValueBeenSubmitted ? (
        <div className="flex w-full h-9 items-center justify-center text-grey">
            <p className="font-semibold">Thank you!</p>
        </div>
    ) : (
        <div className="flex">
            <FormTextInput
                id="form-input"
                placeholder={placeholderText}
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value);
                }}
            />
            <button
                style={{ background: buttonColor, color: buttonTextColor }}
                className={`flex items-center justify-center bg-[${buttonTextColor}] h-9 p-2 rounded-r-md text-sm`}
                disabled={inputValue.length < 1}
                onClick={(event) => {
                    event.preventDefault();
                    onSubmitFormInputValue(inputValue);
                    onConvertClick(event);
                    setHasFormValueBeenSubmitted(true);
                }}
            >
                <img
                    src={ArrowWhiteIcon}
                    alt="arrow icon"
                    className="h-5 w-5 rotate-180 hover:translate-x-1 transition-all duration-500"
                />
            </button>
        </div>
    );
};
