import React from 'react';
import { ClicksService, PostFormSubmissionService, ViewsService } from './api';
import { LegacyCta } from './components/LegacyCta';
import { createCtaData, CtaData } from './utils/create-cta-data';
import ExternalLinkIcon from './assets/icons/external-link-white.svg';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';

export const App: React.FC = () => {
    const [ctaData, setCtaData] = React.useState<CtaData>();
    const [viewId, setViewId] = React.useState<string>();

    const [contentUrl, setContentUrl] = React.useState<string>();
    const [isStaticContentType, setIsStaticContentType] = React.useState<boolean>();

    const [hide, setHide] = React.useState(false);

    const handleSubmitFormInputValue = React.useCallback(
        (inputValue: string) => {
            PostFormSubmissionService.postFormSubmissionCreate({
                requestBody: {
                    cta: ctaData.id,
                    link_id: (window as any).sniply_link_id,
                    submission: inputValue
                }
            }).then(() =>
                setTimeout(() => {
                    setHide(true);
                }, 5000)
            );
        },
        [ctaData]
    );

    const handleTrackConversion = React.useCallback(() => {
        ClicksService.clicksCreate({
            requestBody: {
                link: (window as any).sniply_link_id,
                referrer: (window as any).sniply_referrer,
                device: (window as any).sniply_user_device,
                ip: (window as any).sniply_user_ip,
                cta: ctaData.id
            }
        });
    }, [ctaData]);

    React.useEffect(() => {
        const ctaDataSetByServer = (window as any).ctaData;

        if (ctaDataSetByServer) {
            setCtaData(createCtaData(ctaDataSetByServer));
            console.log('Loading CTA data passed by server:', ctaDataSetByServer);
        }
    }, []);

    React.useEffect(() => {
        const isStaticContentSetByServer = (window as any).sniply_static_page_used;

        if (isStaticContentSetByServer) {
            setIsStaticContentType(true);

            const contentUrlSetByServer = (window as any).sniply_non_static_url;
            setContentUrl(contentUrlSetByServer);
            console.log('Setting Original Content Url from server:', contentUrlSetByServer);
        }
    }, []);

    React.useEffect(() => {
        if (ctaData && (window as any).sniply_link_id) {
            ViewsService.viewsCreate({
                requestBody: {
                    link: (window as any).sniply_link_id,
                    referrer: (window as any).sniply_referrer,
                    device: (window as any).sniply_user_device,
                    ip: (window as any).sniply_user_ip,
                    cta: ctaData.id
                }
            }).then((result) => {
                setViewId(result.id);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctaData]);

    React.useEffect(() => {
        if (viewId) {
            let timeOnPage = 0;

            const interval = setInterval(() => {
                const newTimeOnPage = timeOnPage + 10;

                ViewsService.viewsPartialUpdate({
                    id: viewId,
                    requestBody: { change: 'time', time_on_page: newTimeOnPage }
                }).then(() => {
                    console.log('Tracking time on page', newTimeOnPage);
                });

                timeOnPage = newTimeOnPage;
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [viewId]);

    const isMobileScreen = useIsMobileScreenSize();

    return ctaData ? (
        !hide ? (
            <div className="font-poppins">
                {isStaticContentType && contentUrl && (
                    <a
                        href={contentUrl}
                        target="_blank"
                        rel="noreferrer"
                        className={`absolute ${
                            ctaData.position.startsWith('bottom')
                                ? 'top-2 right-2'
                                : 'bottom-4 right-2'
                        } z-100 p-2 bg-grey bg-opacity-50 text-white text-sm flex items-center`}
                    >
                        <img
                            src={ExternalLinkIcon}
                            alt="external link icon"
                            className={`${isMobileScreen ? 'h-4 w-4' : 'h-5 w-5'}`}
                        />
                    </a>
                )}
                {isStaticContentType && (
                    <div
                        className={`flex absolute top-0 left-0 h-full w-full bg-grey bg-opacity-5 z-10 pointer-events-none`}
                    />
                )}
                <LegacyCta
                    configuration={ctaData}
                    disclaimerHref="https://snip.ly/whats-this2"
                    onSubmitFormInputValue={handleSubmitFormInputValue}
                    onConvertClick={handleTrackConversion}
                />
            </div>
        ) : null
    ) : null;
};
