import { getCtaTypeFromResponse } from '../utils/get-cta-type-from-response';

interface CtaQuestionnaireOption {
    readonly id: string;
    readonly label: string;
}

export type CtaTheme = 'social' | 'fullwidth' | 'candy' | 'bean';
export type CtaPosition = 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
export type CtaType = 'button' | 'text-link' | 'form' | 'image-ad';
export interface CtaData {
    readonly id: string;
    readonly name: string;
    readonly type: CtaType;
    readonly imageUrl: string;
    readonly headlineText: string;
    readonly messageText: string;
    readonly messageTextColor: string;
    readonly urlToBePromoted: string;
    readonly backgroundColor: string;
    readonly buttonColor: string;
    readonly buttonText: string;
    readonly buttonTextColor: string;
    readonly linkText: string;
    readonly linkTextColor: string;
    readonly formButtonTextColor: string;
    readonly formButtonColor: string;
    readonly formPlaceholderText: string;
    readonly adImageUrl: string;
    readonly position: CtaPosition;
    readonly theme: CtaTheme;
    readonly showSniplyLogo: boolean;
    readonly showWhiteSniplyLogo?: boolean;
    readonly createdDate: string;
    readonly numberOfViews: number;
    readonly numberOfClicks: number;
    readonly archived: boolean;
    // Props related to new CTA styles
    readonly questionnaireOptions?: CtaQuestionnaireOption[];
    readonly questionnaireQuestionText?: string;
}

export function createCtaPosition(position: string): CtaPosition {
    switch (position) {
        case 'bottom':
            return 'bottom-left';
        case 'top':
            return 'top-left';
        default:
            return position as CtaPosition;
    }
}

//TODO BE to deliver the following new data keys:
// headline
// form_action?.button_text
// form_action?.questionnaire_options
// form_action?.questionnaire_question

export function createCtaData(cta: any): CtaData {
    const ctaType = getCtaTypeFromResponse(cta);

    return {
        id: cta.id,
        name: cta.name ?? cta.message ?? '',
        type: ctaType,
        messageText: cta.message,
        messageTextColor: cta.message_color,
        urlToBePromoted:
            ctaType === 'image-ad'
                ? cta.image_action_url_utm
                : ctaType === 'form'
                ? ''
                : cta.button_action_url_utm,
        backgroundColor: cta.background_color,
        buttonColor:
            cta.button_action?.background_color ?? cta.form_action?.button_background_color,
        buttonText: !cta.button_action?.is_text ? cta.button_action?.text : '',
        buttonTextColor: !cta.button_action?.is_text
            ? cta.button_action?.text_color
            : '' ?? cta.form_action?.button_text_color,
        linkText: cta.button_action?.is_text ? cta.button_action?.text : '',
        linkTextColor: cta.button_action?.is_text ? cta.button_action?.text_color : '',
        formButtonTextColor: cta.form_action ? cta.form_action?.button_text_color : '',
        formButtonColor: cta.form_action ? cta.form_action?.button_background_color : '',
        formPlaceholderText: cta.form_action?.placeholder_text ?? '',
        adImageUrl: cta.image_action?.image_url,
        position: createCtaPosition(cta.position),
        theme: cta.theme as CtaTheme,
        showSniplyLogo: cta.show_sniply_logo,
        showWhiteSniplyLogo: cta.show_white_sniply_logo,
        createdDate: cta.date_created,
        numberOfViews: cta.cache_view_count,
        numberOfClicks: cta.cache_click_count,
        archived: cta.archived,
        // Props related to new CTA styles
        headlineText: cta.title,
        imageUrl: cta.image_url,
        questionnaireOptions: cta.form_action?.questionnaire_options,
        questionnaireQuestionText: cta.form_action?.questionnaire_question
    };
}
