/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubmitFormSubmission } from '../models/SubmitFormSubmission';
import type { SubmitFormSubmissionRequest } from '../models/SubmitFormSubmissionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PostFormSubmissionService {

    /**
     * @returns SubmitFormSubmission
     * @throws ApiError
     */
    public static postFormSubmissionCreate({
        requestBody,
    }: {
        requestBody: SubmitFormSubmissionRequest,
    }): CancelablePromise<SubmitFormSubmission> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/post_form_submission',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
