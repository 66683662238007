import React from 'react';
import ArrowUpIcon from '../assets/icons/arrow-up-white.svg';
import CloseIcon from '../assets/icons/close.svg';
import DisclaimerInfoIcon from '../assets/icons/disclaimer-info.svg';
import { CtaProps } from './LegacyCta';
import { PoweredBySniply } from './PoweredBySniply';

export const LegacyCtaImage: React.FC<CtaProps> = ({
    configuration: { urlToBePromoted, adImageUrl, position, showSniplyLogo, showWhiteSniplyLogo },
    onConvertClick,
    disclaimerHref
}) => {
    const [hide, setHide] = React.useState(false);

    return hide ? (
        <div
            className={`absolute z-100 bg-white rounded-md ${
                position === 'bottom-left'
                    ? 'bottom-2 left-2'
                    : position === 'bottom-right'
                    ? 'bottom-2 right-2'
                    : position === 'top-left'
                    ? 'top-2 left-2'
                    : 'top-2 right-2'
            } ${
                position === 'bottom-left'
                    ? 'shadow-cta-b-l'
                    : position === 'bottom-right'
                    ? 'shadow-cta-b-r'
                    : position === 'top-left'
                    ? 'shadow-cta-t-l'
                    : 'shadow-cta-t-r'
            }`}
        >
            <div className="relative p-2 w-full h-full rounded-md">
                {adImageUrl ? (
                    <img className="h-20" src={adImageUrl} />
                ) : (
                    <div className="h-20 w-20 bg-white flex items-center justify-center text-xs">
                        Your Image
                    </div>
                )}
                <button
                    className="p-1 absolute -top-2 -right-2 bg-primary flex items-center justify-center rounded-full"
                    onClick={() => setHide(false)}
                >
                    <img src={ArrowUpIcon} alt="arrow icon" className="h-4 w-4" />
                </button>
            </div>
        </div>
    ) : (
        <div
            className={`absolute z-100 bg-white rounded-md ${
                position === 'bottom-left'
                    ? 'bottom-2 left-2 mr-2 mt-2'
                    : position === 'bottom-right'
                    ? 'bottom-2 right-2 ml-2 mt-2'
                    : position === 'top-left'
                    ? 'top-2 left-2 mr-2 mb-2'
                    : 'top-2 right-2 ml-2 mb-2'
            } ${
                position === 'bottom-left'
                    ? 'shadow-cta-b-l'
                    : position === 'bottom-right'
                    ? 'shadow-cta-b-r'
                    : position === 'top-left'
                    ? 'shadow-cta-t-l'
                    : 'shadow-cta-t-r'
            }`}
        >
            <div className="relative p-2 flex w-full h-full rounded-md">
                <a
                    className="w-full h-full"
                    href={urlToBePromoted}
                    target="_blank"
                    rel="noreferrer"
                    onClick={onConvertClick}
                >
                    {adImageUrl ? (
                        <img className="w-full max-h-[96vh]" src={adImageUrl} />
                    ) : (
                        <div className="h-40 w-80 bg-white flex items-center justify-center">
                            Your Image
                        </div>
                    )}
                </a>
                <div className="absolute top-3 right-3 flex">
                    <a
                        className="ml-4 shrink-0 rounded-full bg-white"
                        href={disclaimerHref}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={DisclaimerInfoIcon}
                            alt="dislaimer info icon"
                            className="h-4 w-4"
                        />
                    </a>
                    <button
                        className="ml-2 shrink-0 rounded-full bg-white"
                        onClick={() => setHide(true)}
                    >
                        <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                    </button>
                </div>
                <div className="absolute bottom-3 right-3">
                    {showSniplyLogo && (
                        <PoweredBySniply
                            backgroundColor={showWhiteSniplyLogo ? '#2681DB' : '#E2E8EE'}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
